<template>
  <div class="sudiaoba-subpage">
    <el-row :gutter="20">
      <el-col
        :span="24"
        v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0)"
      >
        <el-alert
          v-if="warningText"
          type="warning"
          :title="warningText"
          show-icon
          effect="dark"
          :closable="false"
        >
        </el-alert>
        <div v-if="!warningText">
          <div class="title title-md">{{ $t("subpage_tip1") }}</div>
          <el-form ref="dynamicValidateForm" :model="form">
            <el-form-item
              v-for="item in formItems"
              :key="item.id"
              :label="item.title"
              :prop="item.key"
              :rules="[{ required: true, message: $t('required') }]"
            >
              <el-select
                style="width: 100%"
                v-model="form[item.key]"
                v-if="item.categoryId === 3"
                @change="change(item.key, $event, getSelectOptions(item))"
                :placeholder="item.description"
              >
                <el-option
                  v-for="sub in getSelectOptions(item)"
                  :key="sub.id"
                  :label="sub.title || sub.nameCn"
                  :value="sub.id"
                ></el-option>
              </el-select>
              <el-input
                v-model="form[item.key]"
                v-if="item.categoryId === 1"
              ></el-input>
              <el-radio-group
                v-model="form[item.key]"
                v-if="item.categoryId === 2"
              >
                <el-radio
                  v-for="sub in item.items"
                  :key="sub.id"
                  :label="sub.id"
                  >{{ sub.title }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-button
                :loading="loading"
                style="width: 100%"
                type="primary"
                @click="onSubmit"
                >{{ $t("next_page") }}</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
import Fingerprint from "../../common/fingerprint";
import { isMobile } from "../../common/util";
import { get, pick, find, has, intersectionBy } from "lodash-es";

export default {
  data() {
    return {
      form: {},
      formItems: [],
      cities: [],
      districts: [],
      surveyResData: {},
      warningText: null,
      loading: false,
      otherItem: {
        id: "-1",
        title: this.$t("other"),
        sortBy: 999999999,
        sdbId: 0,
      },
    };
  },
  async created() {
    this.loading = true;
    const { webid, pkg, encryptId, uid } = this.$route.query;
    const res = await this.$http.get(`/api/v1/Surveys/{encryptId}/Validator`, {
      params: {
        encryptId,
        webId: webid,
        uid,
        package: pkg,
        anonymousId: null,
      },
    });

    if (res.status === 200) {
      if (get(res.data, "QRCode.onlyMobile") && !isMobile()) {
        if (res.data.webId === "sudiaoba") {
          return (location.href = get(res.data, "QRCode.redirect"));
        }
        return (this.warningText = this.$t("subpage_tip2"));
      } else if (isMobile() && get(res.data, "QRCode.onlyPC")) {
        return (this.warningText = this.$t("subpage_tip3"));
      }
      this.surveyResData = res.data;
      this.formItems = res.data.questions;

      if (get(res.data, "selected.isSelected")) {
        this.form = { ...res.data.selected };

        // 对provinceId cityId districtId 做hack处理
        if (has(this.form, "provinceId")) {
          this.form.provinceId = +this.form.provinceId;
        }
        if (has(this.form, "cityId")) {
          this.form.cityId = +this.form.cityId;
        }
        if (has(this.form, "districtId")) {
          this.form.districtId = +this.form.districtId;
        }

        if (!this.isExistInItems("provinceId")) {
          delete this.form.provinceId;
          delete this.form.cityId;
          delete this.form.districtId;
        }
        if (!this.isExistInItems("age")) {
          delete this.form.age;
        }
        if (!this.isExistInItems("gender")) {
          delete this.form.gender;
        }
        if (has(this.form, "provinceId")) {
          await this.getCities(this.form.provinceId, this.form.cityId);
        }
        if (has(this.form, "cityId")) {
          var fd = find(this.cities, { id: this.form.cityId });
          if (fd) {
            await this.getDistricts(fd.sdbCityId, this.form.districtId);
          }
        }
        this.$refs["dynamicValidateForm"].clearValidate();
      }

      this.loading = false;
    }
  },
  methods: {
    onSubmit() {
      this.$refs["dynamicValidateForm"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let params = {
            ...pick(this.surveyResData, [
              "encryptId",
              "webId",
              "uid",
              "anonymousId",
            ]),
            ...this.form,
            fingerprintCode: new Fingerprint().get(),
          };
          if (!params.anonymousId) {
            delete params.anonymousId;
          }
          const res = await this.$http.post(
            `/api/v1/Surveys/${this.surveyResData.encryptId}/${this.surveyResData.method}`,
            null,
            {
              params,
            }
          );
          if (res.status === 200) {
            location.href = res.data.url;
          } else {
            this.$message.error({
              dangerouslyUseHTMLString: true,
              message: res.statusText,
            });
          }
          this.loading = false;
        }
      });
    },
    async change(key, value, originItems) {
      var fd = find(originItems, { id: value }) || {};
      if (key === "provinceId") {
        delete this.form.cityId;
        delete this.form.districtId;
        await this.getCities(fd.id);
      } else if (key === "cityId") {
        delete this.form.districtId;
        await this.getDistricts(fd.sdbCityId);
      }
    },
    async getCities(id, selectedCityId) {
      if (id && id !== "-1") {
        const res = await this.$http.get(
          `/api/v1/AliProvinces/${id}/AliCities`
        );
        if (res.status === 200) {
          const cacheCities = get(this.surveyResData, "cache.cities");
          this.cities = [
            ...(cacheCities
              ? this.getIntersectionArray(
                  res.data,
                  "sdbCityId",
                  cacheCities,
                  "CityID"
                )
              : res.data),
          ];
          if (this.surveyResData.isHit) this.cities.push(this.otherItem);
        }
      } else {
        this.cities = [this.otherItem];
      }
      if (selectedCityId && !find(this.cities, { id: selectedCityId })) {
        this.form.cityId = null;
        this.form.districtId = null;
      }
    },
    async getDistricts(id, selecteDistrictId) {
      if (id && id !== "-1") {
        const res = await this.$http.get(`/api/v1/Cities/${id}/Districts`);
        if (res.status === 200) {
          const cacheDistricts = get(this.surveyResData, "cache.districts");
          this.districts = [
            ...(cacheDistricts
              ? this.getIntersectionArray(
                  res.data,
                  "id",
                  cacheDistricts,
                  "DistrictID"
                )
              : res.data),
          ];
          if( this.surveyResData.isHit) this.districts.push(this.otherItem);
        }
      } else {
        this.districts = [this.otherItem];
      }

      if (
        selecteDistrictId &&
        !find(this.districts, { id: selecteDistrictId })
      ) {
        this.form.districtId = undefined;
      }
    },
    getSelectOptions(item) {
      switch (item.key) {
        case "cityId":
          return this.cities;
        case "districtId":
          return this.districts;
        case "provinceId":
          return item.items.map((v) => ({
            ...v,
            id: +v.id,
          }));
        default:
          return item.items;
      }
    },
    isExistInItems(key) {
      return find(get(find(this.surveyResData.questions, { key }), "items"), {
        // question里的id 都是string，这里+ “” 不能删！！
        id: this.form[key] + "",
      });
    },
    getIntersectionArray(a, aKey, b, bKey) {
      const targetBArr = b.map((item) => ({ [aKey]: item[bKey] }));
      return intersectionBy(a, targetBArr, aKey);
    },
  },
};
</script>
